@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@100..700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* styles/global.css */
h1, h2, h3, h4, h5, h6 {
    @apply font-heading;
}

body {
    @apply bg-gray-900 text-white; /* Set background to black and text to white */
}

.text-shadow {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
