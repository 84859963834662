@keyframes move-stars {
    from {
        transform: translateY(100vh);
    }
    to {
        transform: translateY(-100vh);
    }
}

.stars-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background: linear-gradient(to bottom, #0f0f3f, #000033);
    overflow: hidden;
}

.star-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.star {
    position: absolute;
    width: 2px;
    height: 2px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    animation: move-stars linear infinite;
}

#stars .star {
    animation-duration: 15s;
    opacity: 0.5;
}

#stars2 .star {
    width: 3px;
    height: 3px;
    animation-duration: 20s;
    opacity: 0.7;
}

#stars3 .star {
    width: 4px;
    height: 4px;
    animation-duration: 25s;
    opacity: 0.9;
}

#stars4 .star {
    width: 2px;
    height: 2px;
    animation-duration: 30s;
    opacity: 0.6;
}


.v-slider-frame {
    border: 1px solid #4BB3FD;
    height: 50px;
    overflow: hidden;
    text-align: center;
}
ul.v-slides {
    list-style-type: none;
    transform: translateY(50px);
    padding: 0;
}
.v-slide {
    font-size: 24px;
    line-height: 50px;
    color: #4BB3FD;
}


.blog h1, .blog h2, .blog h3, .blog h4, .blog h5, .blog h6 {
    padding: 5px;
    font-size: 200% !important;
}