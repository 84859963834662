.cosmic-gradient {
    background: linear-gradient(to bottom right, #4f46e5ff 0%, #0000ff00 70%), linear-gradient(to bottom left, #9333eaff 0%, #0000ff00 70%), linear-gradient(to top left, #2a0085ff 0%, #0000ff00 70%), linear-gradient(to top, #000000ff 0%, #000000ff 100%);
}
.containerGradient {
    position: relative; /* Ensure positioning for the pseudo-element */
    background-color: rgba(0,0,0,0.2); /* Fallback background color */
    overflow: hidden; /* Prevent pseudo-element overflow */
}

.containerGradient::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #161616 10px), repeating-linear-gradient(#00000055, #000000);
    opacity: 0.4; /* Apply opacity only to the background */
    z-index: -1; /* Ensure the pseudo-element is behind the content */
}
