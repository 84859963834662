/* Add any custom styles for the icons */
.icon {
    width: 3rem;  /* Adjust the size of the icons */
    height: 3rem;
    fill: white;  /* Ensure the icon is white */
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.services-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.service-card {
    position: relative;
    width: 250px;
    height: 250px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.service-card h3 {
    color: #d1d5db;
}

.service-card p {
    color: #9ca3af;
}

.service-card:hover {
    opacity: 0.8;
}

/* Ensure the perspective for 3D flip */
.perspective {
    perspective: 1000px;
}

/* Flip card logic */
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.7s ease-in-out;
}

/* Flip rotation */
.flip-card-inner:hover {
    transform: rotateY(180deg);
}

/* Front and back faces */
.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: inherit;
}

/* Back face rotation */
.flip-card-back {
    transform: rotateY(180deg);
}
